risk-categories-view {
  .risk-categories {

    &__input-container {
      display: flex;
    }

    &__table {
      box-sizing: border-box;
      margin: 20px;
      width: calc(100% - 40px);

      td, th {
        &:first-child {
          width: 30px;
        }

        &:last-child {
          width: 80px;
        }
      }
    }

    &__open-button {
      width: 80px;
    }
  }
}