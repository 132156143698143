.main-view credit-scoring-modal {
  --poor-color: var(--danger-color);
  --poor-background-color: var(--danger-background-color);
  --bad-color: var(--bloody-color);
  --bad-background-color: var(--bloody-background-color);
  --average-color: var(--warning-color);
  --average-background-color: var(--warning-background-color);
  --excellent-color: var(--success-color);
  --excellent-background-color: var(--success-background-color);
  --outstanding-color: var(--primary-color);
  --outstanding-background-color: var(--hover-background-color);
}
.main-view credit-scoring-modal .modal {
  background-color: var(--body-color);
  margin-left: -35%;
  width: 70%;
}
.main-view credit-scoring-modal .modal .modal-header,
.main-view credit-scoring-modal .modal .modal-body,
.main-view credit-scoring-modal .modal .modal-footer {
  background: inherit;
  box-shadow: none;
}
@media (max-width: 767px) {
  .main-view credit-scoring-modal .modal {
    width: calc(100% - 40px);
    margin-left: 0;
  }
}
.main-view credit-scoring-modal .grid {
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) minmax(400px, 3fr);
  grid-column-gap: var(--box-padding);
  grid-row-gap: var(--box-padding);
  margin: var(--box-padding) 0;
}
.main-view credit-scoring-modal cart {
  background: var(--white);
  display: block;
  padding: var(--box-padding);
  border-radius: var(--xsmall-gap);
}
.main-view credit-scoring-modal .cart--large {
  margin: var(--box-padding) 0;
}
.main-view credit-scoring-modal .cart--large:last-child {
  margin-bottom: 0;
}
.main-view credit-scoring-modal cart-body {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-view credit-scoring-modal cart-body > * {
  margin-bottom: var(--small-gap);
}
.main-view credit-scoring-modal cart-body > *:last-child {
  margin-bottom: 0;
}
.main-view credit-scoring-modal cart-body-title {
  align-self: flex-start;
  font-size: var(--font-size-m);
}
.main-view credit-scoring-modal cart-header {
  display: block;
  padding-bottom: var(--small-gap);
  margin-bottom: var(--small-gap);
  border-bottom: 0.03rem solid rgba(0, 0, 0, 0.1);
}
.main-view credit-scoring-modal score-progress {
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
}
.main-view credit-scoring-modal score-progress > * {
  border-radius: var(--default-border-radius);
  flex-basis: 0;
  flex-grow: 1;
  font-size: var(--font-size-xs);
  line-height: 3.5rem;
  height: var(--xsmall-gap);
  margin-right: var(--xsmall-gap);
  position: relative;
}
.main-view credit-scoring-modal score-progress > *:last-child {
  margin-right: 0;
}
.main-view credit-scoring-modal score-progress > * svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.main-view credit-scoring-modal score-progress-poor {
  background: var(--poor-color) !important;
}
.main-view credit-scoring-modal score-progress-poor svg {
  stroke: var(--poor-color) !important;
}
.main-view credit-scoring-modal score-progress-bad {
  background: var(--bad-color) !important;
}
.main-view credit-scoring-modal score-progress-bad svg {
  stroke: var(--bad-color) !important;
}
.main-view credit-scoring-modal score-progress-average {
  background: var(--average-color) !important;
}
.main-view credit-scoring-modal score-progress-average svg {
  stroke: var(--average-color) !important;
}
.main-view credit-scoring-modal score-progress-good {
  background: var(--good-color) !important;
}
.main-view credit-scoring-modal score-progress-good svg {
  stroke: var(--good-color) !important;
}
.main-view credit-scoring-modal score-progress-excellent {
  background: var(--excellent-color) !important;
}
.main-view credit-scoring-modal score-progress-excellent svg {
  stroke: var(--excellent-color) !important;
}
.main-view credit-scoring-modal score-progress-outstanding {
  background: var(--outstanding-color) !important;
}
.main-view credit-scoring-modal score-progress-outstanding svg {
  stroke: var(--outstanding-color) !important;
}
.main-view credit-scoring-modal score-progress-bad {
  flex-grow: 5;
}
.main-view credit-scoring-modal .text-subtle {
  font-size: var(--font-size-xs);
}
.main-view credit-scoring-modal .text--headline {
  font-size: var(--font-size-xl);
  padding: var(--box-padding);
}
.main-view credit-scoring-modal .text--headline .color-poor {
  color: var(--poor-color) !important;
}
.main-view credit-scoring-modal .text--headline .color-bad {
  color: var(--bad-color) !important;
}
.main-view credit-scoring-modal .text--headline .color-average {
  color: var(--average-color) !important;
}
.main-view credit-scoring-modal .text--headline .color-good {
  color: var(--good-color) !important;
}
.main-view credit-scoring-modal .text--headline .color-excellent {
  color: var(--excellent-color) !important;
}
.main-view credit-scoring-modal .text--headline .color-outstanding {
  color: var(--outstanding-color) !important;
}
.main-view credit-scoring-modal .header--two-columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-view credit-scoring-modal .columns--space-between {
  width: 100%;
}
.main-view credit-scoring-modal .columns--space-between > * {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-s);
  padding: var(--small-gap) 0;
  border-bottom: 0.03rem solid rgba(0, 0, 0, 0.1);
}
.main-view credit-scoring-modal .columns--space-between > * *:first-of-type {
  color: var(--text-color-light);
  font-size: var(--font-size-xs);
  font-weight: 600;
  text-transform: uppercase;
}
.main-view credit-scoring-modal .chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 calc((var(--small-gap))*-1);
}
.main-view credit-scoring-modal .chart-container > * {
  margin: var(--small-gap) var(--small-gap);
}
.main-view credit-scoring-modal amortization-preview {
  width: 100%;
}
@media print {
  .main-view credit-scoring-modal .modal {
    background: none;
    border: none;
    box-shadow: none;
    margin: 0;
    width: 100%;
    --cart-border-width: 1px;
  }
  .main-view credit-scoring-modal .modal-header {
    padding-left: 0;
  }
  .main-view credit-scoring-modal .modal-body {
    padding: var(--cart-border-width);
    max-height: 100%;
    overflow: hidden;
  }
  .main-view credit-scoring-modal cart {
    position: relative;
  }
  .main-view credit-scoring-modal cart:after {
    border-style: solid;
    border-width: var(--cart-border-width);
    border-color: var(--disabled-button-background-color);
    border-radius: inherit;
    bottom: calc(var(--cart-border-width) + 1px);
    content: "";
    left: calc(var(--cart-border-width) + 1px);
    position: absolute;
    top: calc(var(--cart-border-width) + 1px);
    right: calc(var(--cart-border-width) + 1px);
  }
  .main-view credit-scoring-modal .cart--large,
  .main-view credit-scoring-modal .grid {
    page-break-inside: avoid;
  }
}
@media print {
  body.credit-scoring-modal {
    background: none;
    visibility: hidden;
  }
  body.credit-scoring-modal credit-scoring-modal {
    visibility: visible;
  }
}
