customer-profile-body {
  .profile-body .tab-menu ul.nav {
    margin-bottom:0;
  }

  .profile-body .tab-content {
    .form-header {
      color: darkgray;
      text-align: right;
      border-bottom: solid 1px lightgray;
      margin: 20px 20px 0 20px;
      padding-right: 10px;
    }
  }

  .profile-body hr {
    margin: 0;
    border: none;
    border-top: solid 1px lightgray;
  }

  .profile-body form {
    margin-bottom: 0;
    padding-top: 0;
  }

  .profile-body__table {
    box-sizing: border-box;
    margin: 20px;
    width: calc(100% - 40px);

    input, select {
      margin: 0;
    }

    .no-column-header {
      width: 40px;
    }
  }

  #cpr_tab_documents .doc-form {
    min-height: 200px;
  }

  #cpr_tab_income hr {
    margin-top: 2px;
    margin-bottom: 30px;
  }

  /*#cpr_tab_income */

  #cpr_inc_other_description {
    width: 607px;
  }

  #cpr_tab_income .template {
    display: none;
  }

  #cpr_tab_related_people {
    padding-top: 0;
  }

  .profile-related-people {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;

    input, select {
      margin-bottom: 0;
    }

    select {
      width: auto;
    }

    input[disabled] {
      border: none;
      outline: 0;
      background-color: transparent;
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    .vertical-center {
      padding: 4px;
    }
  }

  div[ng-transclude]:empty {
    display: none;
  }

}
