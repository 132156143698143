risk-categories-view .risk-categories__input-container {
  display: flex;
}
risk-categories-view .risk-categories__table {
  box-sizing: border-box;
  margin: 20px;
  width: calc(100% - 40px);
}
risk-categories-view .risk-categories__table td:first-child,
risk-categories-view .risk-categories__table th:first-child {
  width: 30px;
}
risk-categories-view .risk-categories__table td:last-child,
risk-categories-view .risk-categories__table th:last-child {
  width: 80px;
}
risk-categories-view .risk-categories__open-button {
  width: 80px;
}
