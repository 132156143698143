customer-risk-profile-page {

  .right-field-title {
    margin-left: 5px;
  }

  .watchlist-button {
    margin-left: 10px;
  }
}
